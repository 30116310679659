import Vue from 'vue'
import store from '@/store'

Vue.mixin({
	data() {
		return {
		}
	},
	computed: {
		isUserAuth: () => {
			return !!(store.state.user && Object.keys(store.state.user.userData).length)
		},
		state () {
			return store.getters
		},
		store () {
			return store
		},
		isMobile () {
			return this.$vuetify.breakpoint.smAndDown
		}
	},
	methods: {
		trans: (key, vars = {}) => {
			const splitKey = key.split('.')
			const trans = store.state.translations.translations

			if (trans && trans[key]) {
				for (let varName in vars) {
					if (vars[varName] !== null) {
						trans[key] = trans[key].replace(':' + varName, vars[varName])
					}
				}
				return trans[key]
			}

			if (splitKey.length === 1) {
				return (trans && trans[key]) || key
			} else {
				if (trans[splitKey[0]]) {
					const groupName = splitKey[0]
					splitKey.splice(0, 1)
					const sliseKey = splitKey.join('.')
					if (trans[groupName] && trans[groupName][sliseKey]) {
						return trans[groupName][sliseKey]
					}
					return sliseKey
				} else {
					return key
				}
			}
		}
	}
})
