import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import moment from 'vue-moment'

// import css

import '@/assets/scss/app.scss'

// import plugins

import './plugins/axios'
import vuetify from './plugins/vuetify'


// requires

require('@/plugins/vueMixin')

Vue.use(Notifications)
Vue.use(moment)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	axios,
	VueAxios,
	render: h => h(App)
}).$mount('#app')
