import ApiBase from '@/api/base'

export default {
	state: {
		homeCryptoPayments: []
	},
	getters: {
		homeCryptoPayments: state => {
			return state.homeCryptoPayments.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.imageUrl
				return item
			})
		}
	},
	mutations: {
		SET_HOME_CRYPTO_PAYMENTS(state, list) {
			state.homeCryptoPayments = list
		}
	},
	actions: {
		setHomeCryptoPayments(context, list) {
			context.commit('SET_HOME_CRYPTO_PAYMENTS', list)
		}
	}
}
