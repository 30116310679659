import ApiBase from '@/api/base'

export default {
	state: {
		homeComparingProviders: []
	},
	getters: {
		homeComparingProviders: state => {
			return state.homeComparingProviders.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.imageUrl
				return item
			})
		}
	},
	mutations: {
		SET_HOME_COMPARING_PROVIDERS(state, list) {
			state.homeComparingProviders = list
		}
	},
	actions: {
		setHomeComparingProviders(context, list) {
			context.commit('SET_HOME_COMPARING_PROVIDERS', list)
		}
	}
}
