export default {
	state: {
		acceptCoinsRequests: []
	},
	getters: {
		acceptCoinsRequests: state => state.acceptCoinsRequests
	},
	mutations: {
		SET_ACCEPT_COINS_REQUESTS (state, list) {
			state.acceptCoinsRequests = list
		}
	},
	actions: {
		setAcceptCoinsRequests (context, list) {
			context.commit('SET_ACCEPT_COINS_REQUESTS', list)
		}
	}
}
