import ApiBase from '@/api/base'

export default {
	state: {
		homeChooseUsItems: []
	},
	getters: {
		homeChooseUsItems: state => {
			return state.homeChooseUsItems.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.imageUrl
				return item
			})
		}
	},
	mutations: {
		SET_HOME_CHOOSE_US_ITEMS(state, list) {
			state.homeChooseUsItems = list
		}
	},
	actions: {
		setHomeChooseUsItems(context, list) {
			context.commit('SET_HOME_CHOOSE_US_ITEMS', list)
		}
	}
}
