export default {
	state: {
		newPositionRequests: []
	},
	getters: {
		newPositionRequests: state => state.newPositionRequests
	},
	mutations: {
		SET_NEW_POSITION_REQUESTS(state, list) {
			state.newPositionRequests = list
		}
	},
	actions: {
		setNewPositionRequests(context, list) {
			context.commit('SET_NEW_POSITION_REQUESTS', list)
		}
	}
}
