import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/pages/admin-auth/AdminAuth')
		}
	]
}
