import ApiBase from '@/api/base'

export default {
	state: {
		homeMedalList: []
	},
	getters: {
		homeMedalList: state => {
			return state.homeMedalList.map(medal => {
				medal.imageUrl = ApiBase.baseUrl() + '/' + medal.imageUrl
				return medal
			})
		}
	},
	mutations: {
		SET_HOME_MEDAL_LIST(state, list) {
			state.homeMedalList = list
		}
	},
	actions: {
		setHomeMedalList(context, list) {
			context.commit('SET_HOME_MEDAL_LIST', list)
		}
	}
}
