export default {
	state: {
		newPartnerRequests: []
	},
	getters: {
		newPartnerRequests: state => state.newPartnerRequests
	},
	mutations: {
		SET_NEW_PARTNER_REQUESTS(state, list) {
			state.newPartnerRequests = list
		}
	},
	actions: {
		setNewPartnerRequests(context, list) {
			context.commit('SET_NEW_PARTNER_REQUESTS', list)
		}
	}
}
