export default {
	state: {
		worldsBestItems: []
	},
	getters: {
		worldsBestItems: state => state.worldsBestItems
	},
	mutations: {
		SET_WORLDS_BEST_ITEMS(state, list) {
			state.worldsBestItems = list
		}
	},
	actions: {
		setWorldsBestItems(context, list) {
			context.commit('SET_WORLDS_BEST_ITEMS', list)
		}
	}
}
