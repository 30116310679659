import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import translationsAll from './modules/translationsAll'
import translations from './modules/translations'
import homeMedalStore from './modules/home-medal-store'
import homeSponsorStore from './modules/home-sponsor-store'
import worldsBestItemsStore from './modules/worlds-best-items-store'
import homeChooseUsItemStore from './modules/home-choose-us-item-store'
import homeCryptoPaymentStore from './modules/home-crypto-payment-store'
import homeComparingProvidersCategoryStore from './modules/home-comparing-providers-category-store'
import homeComparingProvidersStore from './modules/home-comparing-providers-store'
import supportedCoinsStore from './modules/supported-coin-store'
import effortlessIntegrationItemStore from './modules/effortless-integration-item-store'
import testimonialStore from './modules/testimonial-store'
import socialMediaStore from './modules/social-media-store'
import settingStore from './modules/setting-store'
import faqStore from './modules/faq-store'
import CustomPageStore from './modules/custom-page-store'
import newPartnerRequestStore from './modules/new-partner-request-store'
import openPositionsStore from './modules/open-positions-store'
import newPositionRequests from './modules/new-position-request-store'
import contactSalesRequests from './modules/contact-sales-request-store'
import acceptCoinsRequests from './modules/accept-coins-request-store'
import appLangsStore from './modules/app-langs-store'
import postsStore from './modules/post-store'
import teamStore from './modules/team-store'
import partnersStore from './modules/partners-store'
import employeeComments from './modules/employee-comments-store'
import subscribeUsersStore from './modules/subscribe-users-store'
import emails from './modules/emails'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		translationsAll,
		translations,
		homeMedalStore,
		homeSponsorStore,
		worldsBestItemsStore,
		homeChooseUsItemStore,
		homeCryptoPaymentStore,
		homeComparingProvidersCategoryStore,
		homeComparingProvidersStore,
		supportedCoinsStore,
		effortlessIntegrationItemStore,
		testimonialStore,
		socialMediaStore,
		settingStore,
		faqStore,
		CustomPageStore,
		newPartnerRequestStore,
		openPositionsStore,
		newPositionRequests,
		contactSalesRequests,
		acceptCoinsRequests,
		appLangsStore,
		postsStore,
		teamStore,
		partnersStore,
		employeeComments,
		emails,
		subscribeUsersStore
	}
})
