import ApiBase from '@/api/base'

export default {
	state: {
		socialMediaList: []
	},
	getters: {
		socialMediaList: state => {
			return state.socialMediaList.map(media => {
				media.fullImageUrl = ApiBase.baseUrl() + '/' + media.imageUrl
				return media
			})
		}
	},
	mutations: {
		SET_SOCIAL_MEDIA_LIST(state, list) {
			state.socialMediaList = list
		}
	},
	actions: {
		setSocialMediaList(context, list) {
			context.commit('SET_SOCIAL_MEDIA_LIST', list)
		}
	}
}
