export default {
	state: {
		contactSalesRequests: []
	},
	getters: {
		contactSalesRequests: state => state.contactSalesRequests
	},
	mutations: {
		CONTACT_SALES_REQUESTS(state, list) {
			state.contactSalesRequests = list
		}
	},
	actions: {
		setContactSalesRequests(context, list) {
			context.commit('CONTACT_SALES_REQUESTS', list)
		}
	}
}
