export default {
	state: {
		openPositions: []
	},
	getters: {
		openPositions: state => state.openPositions
	},
	mutations: {
		SET_OPEN_POSITIONS(state, list) {
			state.openPositions = list
		}
	},
	actions: {
		setOpenPositions(context, list) {
			context.commit('SET_OPEN_POSITIONS', list)
		}
	}
}
