import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

//import routes
import Admin from './routes/admin'
import Login from './routes/admin-auth'
import NotFound from './routes/not-found'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import('@/components/admin-container/AdminContainer'),
			children: [
				Admin,
				Login,
				NotFound
			]
		}
	]
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)

	if (!to.meta.middleware) {
		return next()
	}

	const middleware = to.meta.middleware

	if (middleware.length === 0) {
		return next()
	}
	const context = {
		to,
		from,
		next,
		store
	}
	for (var key in middleware) {
		middleware[key]({
			...context
		})
	}
})

export default router
