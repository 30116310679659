import ApiBase from '@/api/base'

export default {
	state: {
		employeeComments: []
	},
	getters: {
		employeeComments: state => state.employeeComments
	},
	mutations: {
		SET_EMPLOYEE_COMMENTS (state, list) {
			state.employeeComments = list.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.image
				return item
			})
		}
	},
	actions: {
		setEmployeeComments (context, list) {
			context.commit('SET_EMPLOYEE_COMMENTS', list)
		}
	}
}
