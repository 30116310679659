export default {
  state: {
    translations: {}
  },
  getters: {
    translations: state => state.translations
  },
  mutations: {
    SET_TRANSLATIONS_LIST (state, translations) {
      state.translations = translations
    }
  },
  actions: {
    setTranslationsList (context, translations) {
      context.commit('SET_TRANSLATIONS_LIST', translations)
    }
  }
}
