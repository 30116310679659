import ApiBase from '@/api/base'

export default {
	state: {
		team: []
	},
	getters: {
		team: state => state.team
	},
	mutations: {
		SET_TEAM_LIST (state, list) {
			state.team = list.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.image
				return item
			})
		}
	},
	actions: {
		setTeamList (context, list) {
			context.commit('SET_TEAM_LIST', list)
		}
	}
}
