import auth from '@/middleware/auth'
import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'Admin',
			component: () => import('@/pages/admin-area/Admin.vue'),
			meta: { middleware: [ auth ] }
		}
	]
}
