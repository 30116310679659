export default {
	state: {
		subscribeUsers: []
	},
	getters: {
		subscribeUsers: state => state.subscribeUsers
	},
	mutations: {
		SET_NEW_SUBSCRIBE_USERS(state, list) {
			state.subscribeUsers = list
		}
	},
	actions: {
		setNewSubscribeUsers(context, list) {
			context.commit('SET_NEW_SUBSCRIBE_USERS', list)
		}
	}
}
