import ApiBase from '@/api/base'

export default {
	state: {
		effortlessIntegrationItems: []
	},
	getters: {
		effortlessIntegrationItems: state => {
			return state.effortlessIntegrationItems.map(sponsor => {
				sponsor.imageUrl = ApiBase.baseUrl() + '/' + sponsor.imageUrl
				return sponsor
			})
		}
	},
	mutations: {
		SET_EFFORTLESS_INTEGRATION_ITEMS(state, list) {
			state.effortlessIntegrationItems = list
		}
	},
	actions: {
		setEffortlessIntegrationItems(context, list) {
			context.commit('SET_EFFORTLESS_INTEGRATION_ITEMS', list)
		}
	}
}
