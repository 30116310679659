import ApiBase from '@/api/base'

export default {
	state: {
		supportedCoins: []
	},
	getters: {
		supportedCoins: state => {
			return state.supportedCoins.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.imageUrl
				return item
			})
		}
	},
	mutations: {
		SET_SUPPORTED_COINS(state, list) {
			state.supportedCoins = list
		}
	},
	actions: {
		setSupportedCoins(context, list) {
			context.commit('SET_SUPPORTED_COINS', list)
		}
	}
}
