<template>
	<v-app id="app">
		<router-view/>
		<notifications position="top right" :width="$vuetify.breakpoint.mobile ? '100%' : '400px'"
		/>
	</v-app>
</template>

<script>
export default {
	name: 'App'
}
</script>
