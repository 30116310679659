export default {
	state: {
		faqList: []
	},
	getters: {
		faqList: state => state.faqList
	},
	mutations: {
		SET_FAQ_LIST(state, list) {
			state.faqList = list
		}
	},
	actions: {
		setFaqList(context, list) {
			context.commit('SET_FAQ_LIST', list)
		}
	}
}
