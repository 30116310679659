import ApiBase from '@/api/base'

export default {
	state: {
		partners: []
	},
	getters: {
		partners: state => state.partners
	},
	mutations: {
		SET_PARTNERS (state, list) {
			state.partners = list.map(item => {
				item.imageUrl = ApiBase.baseUrl() + '/' + item.image
				return item
			})
		}
	},
	actions: {
		setPartners (context, list) {
			context.commit('SET_PARTNERS', list)
		}
	}
}
