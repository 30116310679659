import ApiBase from '@/api/base'
import * as moment from 'moment/moment'

export default {
	state: {
		posts: []
	},
	getters: {
		posts: state => state.posts
	},
	mutations: {
		SET_POSTS (state, list) {
			state.posts = list.map(item => {
				item.date = moment(item.date).toISOString().substr(0, 10)
				item.imageUrl = ApiBase.baseUrl() + '/' + item.image
				return item
			})
		}
	},
	actions: {
		setPosts (context, list) {
			context.commit('SET_POSTS', list)
		}
	}
}
