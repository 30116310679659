export default {
	state: {
		testimonials: []
	},
	getters: {
		testimonials: state => state.testimonials
	},
	mutations: {
		SET_TESTIMONIALS(state, list) {
			state.testimonials = list
		}
	},
	actions: {
		setTestimonials(context, list) {
			context.commit('SET_TESTIMONIALS', list)
		}
	}
}
