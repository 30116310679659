export default {
	state: {
		homeComparingProvidersCategories: []
	},
	getters: {
		homeComparingProvidersCategories: state => state.homeComparingProvidersCategories
	},
	mutations: {
		SET_HOME_COMPARING_PROVIDERS_CATEGORIES(state, list) {
			state.homeComparingProvidersCategories = list
		}
	},
	actions: {
		setHomeComparingProvidersCategories(context, list) {
			context.commit('SET_HOME_COMPARING_PROVIDERS_CATEGORIES', list)
		}
	}
}
