'use strict'

import StorageHelper from '@/helpers/localStorageHelper'
import store from '@/store'

class ApiBase {
	static baseUrl() {
		return `${process.env.VUE_APP_API_URL}`
	}

	static defaultLang() {
		return `${process.env.VUE_APP_LANGUAGE}`
	}

	static authHeaders() {
		return {
			Authorization: 'Bearer ' + (StorageHelper.get('Token')),
		}
	}

	static unauthoriseUser(path) {
		this.setToStore(null, 'setUserDataList', {})
		StorageHelper.set('Token', '')
		window.location = path
	}

	static setToStore(self, name, data, callBack) {
		store.dispatch(name, data)
		if (callBack) {
			callBack()
		}
	}

}

export default ApiBase
