export default {
	state: {
		appLangs: []
	},
	getters: {
		appLangs: state => state.appLangs
	},
	mutations: {
		SET_APP_LANGS (state, list) {
			state.appLangs = list
		}
	},
	actions: {
		setAppLangs(context, list) {
			context.commit('SET_APP_LANGS', list)
		}
	}
}
