export default {
	state: {
		customPages: []
	},
	getters: {
		customPages: state => state.customPages
	},
	mutations: {
		SET_CUSTOM_PAGES(state, list) {
			state.customPages = list
		}
	},
	actions: {
		setCustomPages(context, list) {
			context.commit('SET_CUSTOM_PAGES', list)
		}
	}
}
