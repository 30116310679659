export default {
	state: {
		settingList: []
	},
	getters: {
		settingList: state => state.settingList
	},
	mutations: {
		SET_SETTING_LIST(state, list) {
			state.settingList = list
		}
	},
	actions: {
		setSettingList(context, list) {
			context.commit('SET_SETTING_LIST', list)
		}
	}
}
